import { defineComponent as m, ref as c, openBlock as t, createBlock as g, withCtx as v, resolveDynamicComponent as f, normalizeClass as k, createElementBlock as o, toDisplayString as n, createCommentVNode as a, Fragment as y, createElementVNode as r, createTextVNode as b } from "vue";
import S from "./Prose.js";
import { _ as C } from "./chunks/_plugin-vue_export-helper.CHgC5LLL.js";
import './css/Heading.css';const x = {
  key: 0,
  class: "shortcode-float"
}, B = {
  key: 0,
  class: "shortcode"
}, w = {
  key: 1,
  class: "tags"
}, M = {
  key: 2,
  class: "separator"
}, N = { class: "overline" }, V = {
  key: 0,
  class: "shortcode"
}, p = {
  key: 0,
  class: "shortcode"
}, D = {
  key: 1,
  class: "tags"
}, E = {
  key: 2,
  class: "separator"
}, H = { class: "subline" }, L = /* @__PURE__ */ m({
  __name: "Heading",
  props: {
    /**
     * The heading tag to render.
     *
     * @default 'h1'
     */
    is: {
      type: [Object, String],
      default: "h1"
    },
    /**
     * The main line of the heading, important for SEO.
     * required
     */
    headline: {
      type: String,
      required: !0
    },
    /**
     * For newspaper-style headings, typicall gives abstract/meta-informaton
     * small font-size
     * If provided, contents of the `subline` will be ignored.
     */
    overline: {
      type: String
    },
    /**
     * For default headings, extends the headline with additional information
     * Only shows up, if no overline is provided
     */
    subline: {
      type: String
    },
    /**
     * Tags extend the overline or subline with contextual information like date, time, location
     * if no overline or subline is provided, tags will be shown as overline
     */
    tags: {
      type: String
    },
    /**
     * A unique shortcode representing the data of this heading, for instance "B1" for an event, "26.7" for a date
     * if no overline or subline is provided, tags will be shown as overline
     */
    shortcode: {
      type: String
    },
    isMobile: {
      // for development purposes only > has to be removed
      type: Boolean,
      default: !1
    }
  },
  setup(e) {
    const s = e, l = c(s.overline || !s.subline && s.tags), i = c(!l.value && (s.subline || s.tags)), u = c(s.shortcode && !l.value && !i.value), d = c(s.shortcode && s.isMobile && !u.value), h = c(s.shortcode && !s.isMobile && (l.value || i.value));
    return (O, j) => (t(), g(S, null, {
      default: v(() => [
        (t(), g(f(e.is), {
          class: k(["heading", [l.value || i.value ? "twoliner" : "oneliner", h.value ? "twocolums" : ""]])
        }, {
          default: v(() => [
            h.value ? (t(), o("span", x, n(e.shortcode), 1)) : a("", !0),
            l.value ? (t(), o(y, { key: 1 }, [
              d.value ? (t(), o("span", B, n(e.shortcode), 1)) : a("", !0),
              e.tags ? (t(), o("span", w, n(e.tags), 1)) : a("", !0),
              e.tags && e.overline ? (t(), o("span", M, "//")) : a("", !0),
              r("span", N, n(e.overline), 1)
            ], 64)) : a("", !0),
            r("strong", null, [
              u.value ? (t(), o("span", V, n(e.shortcode), 1)) : a("", !0),
              b(" " + n(e.headline), 1)
            ]),
            i.value ? (t(), o(y, { key: 2 }, [
              d.value ? (t(), o("span", p, n(e.shortcode), 1)) : a("", !0),
              e.tags ? (t(), o("span", D, n(e.tags), 1)) : a("", !0),
              e.tags && e.subline ? (t(), o("span", E, "//")) : a("", !0),
              r("span", H, n(e.subline), 1)
            ], 64)) : a("", !0)
          ]),
          _: 1
        }, 8, ["class"]))
      ]),
      _: 1
    }));
  }
}), I = /* @__PURE__ */ C(L, [["__scopeId", "data-v-467ef391"]]);
export {
  I as default
};
